/**
 * React.
 */
import React, { useState } from 'react';

/**
 * Gatsby.
 */
import { Link } from "gatsby"

const Menu = ({ menu }) => {

  /* Get path name value */
  const path_name = ( typeof document !== 'undefined' ) ? document.location.pathname : ''


  /* Return menu */
  return (
    <div>
      <ul className="menu m-0 mb-3">
        {menu.map(menuItem => {
          const children = menuItem.childItems.nodes.length ? menuItem.childItems.nodes : null
          return children ? (
            <DropdownMenuItem key={Math.random()} parent={menuItem} children={children}/>
          ) : (
            <MenuItem key={Math.random()} menuItem={menuItem} pathName={path_name}/>
          )
        })}
      </ul>
      <div className="clear-left"></div>
    </div>
  )
}

/* Set menu item */
const MenuItem = ({ menuItem, pathName }) => {

  /* Set default class name */
  let classMenu = "menu-item"
  
  /* If menu item url matches page url */
  if ( menuItem.url === pathName ) {

    /* Add class name menu-active ( for CSS purposes ) */
    classMenu = "menu-item menu-active"
  }

  /* Convert menu item label characters */
  menuItem.label = menuItem.label.replace( /&#8211;/g, '-' )

  /* Return menu item */
  return (
    <li className={classMenu} id={menuItem.url}>
      <a href={menuItem.url}>{menuItem.label}</a>
    </li>
  )
}

/* Set dropdown menu item */
const DropdownMenuItem = ({ parent, children }) => {

  /* Set state for mouseover/mouseclick child menu items */
  const [show, setShow] = useState(false)

  /* Return dropdown menu item */
  return (
    <li className={`dropdown menu-item`}>
      <button 
        onClick={() => setShow(true)} 
        className="menu-mouseover-link">

        {/* Parent dropdown menu item label */}
        {parent.label}

        <div style={{ display: show ? "block" : "none" }}>
          <div className="flex-square menu-drop">

            {/* Loop for each dropdown child menu item */}
            {children.map(child => (
              <div key={Math.random()} className="menu-mouseover-links bg-dark">
                - <Link key={Math.random()} to={child.url}>{child.label}<br /></Link>
              </div>
            ))}

          </div>
        </div>
      </button>
    </li>
  )
}

export default Menu
