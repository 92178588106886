/**
 * React.
 */
import * as React from "react"
import PropTypes from "prop-types"

/**
 * Gatsby.
 */
import { useStaticQuery, graphql } from "gatsby"

/**
 * Header.
 * Used for page header.
 */
import Header from "./header"

/**
 * Footer.
 * Used for page footer.
 */
import Footer from "./footer"

/**
 * Layout CSS.
 * Used for page design.
 */
import "./layout.css"

/**
 * Custom CSS.
 * Used for page design.
 */
import "./custom.css"

const Layout = ({ children }) => {

  /* Get default site meta details */
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  /* Return the page content */
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>
          <div id="content">
            {children}
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
