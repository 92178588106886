/**
 * React.
 */
import * as React from "react"

/**
 * Gatsby.
 */
import { useStaticQuery, graphql } from "gatsby"

/**
 * Gatsby Images.
 */
import { StaticImage } from "gatsby-plugin-image"

/**
 * Menu.
 * Used for page navigation.
 */
import Menu from "./menu"

/**
 * Multi-Language Menus.
 * Using JSON data in MenuLang[LANG] file(s).
 * See 'menu/README.txt' for info.
 */
import MenuLangEN from "./menus/MenuLangEN"
import MenuLangFR from "./menus/MenuLangFR"

/**
 * Login Status.
 * Used for current login status.
 */
import LoginStatus from "./loginstatus"

const Header = () => {

  // Set default menu language.
  const data = useStaticQuery(
    graphql`
      {
        wpMenu {
          id
          menuItems {
            nodes {
              parentId
              label
              path
              url
              childItems {
                nodes {
                  label
                  path
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  
  /* Set menu data */
  let menu = data.wpMenu.menuItems.nodes ? data.wpMenu.menuItems.nodes.filter( menuItem => menuItem.parentId === null ) : null     

  /* Set home url */
  let home = '/'

  /* Set default search url */
  let searchUrl  = '/search/'

  /* Get URL so we can display page with chosen language */
  let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''

  /* Switch menu based on language value in url */
  if ( window_url.indexOf( "/fr/" ) > -1 ) {
      menu      = MenuLangFR
      home      = '/fr/'
      searchUrl = '/fr/search/'
  } else {
      menu      = MenuLangEN
      home      = '/'
      searchUrl = '/search/'
  }

  const SearchBar = () => (
    <div className="search-bar row align-items-end">

      {/* Search form */}
      <form
          className="search-bar-form"
          action={searchUrl}
          method="get"
          autoComplete="off"
      >
        <div className="row align-items-end">
          <div className="form-group">

            {/* Input field */}
            <input
              type="text"
              id="search-field"
              className="search-field form-control"
              placeholder=""
              name="s"
              style={{ marginRight: `5px`, float: `left` }}
            /> 

            {/* Submit button */}
            <button className="btn btn-secondary">Search</button> 

          </div>
        </div> 
      </form>
    </div>
  );

  /* Return Header */
  return (
    <header className="header">
      <div className="collapse bg-dark" id="navbarHeader">
        <div className="container">
          <div className="row">

            {/* About */}
            <div className="col-sm-8 col-md-7 py-4 pb-0 mb-0">
              <h4 className="text-white">About</h4>
              <p className="text-muted">Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.</p>
            </div>

            {/* Menu */}
            <div className="col-sm-8 col-md-7 mt-1 mb-0">
              <h4 className="text-white">Menu</h4>
              <Menu menu={menu} />
              <LoginStatus />
            </div>

            {/* Language */}
            <div className="col-sm-8 col-md-7 mt-1 mb-4">
              <h4 className="text-white">Language</h4>
              <ul className="menu m-0 mb-3">
                <li className="dropdown menu-item">
                    <div>
                      <div className="flex-square">
                        <div className="bg-dark">
                          <a href="/">
                            <StaticImage
                              src="../images/united-kingdom.jpg"
                              width={30}
                              quality={100}
                              alt="United Kingdom"
                            />
                          </a>
                          &nbsp;
                          <a href="/fr/">
                              <StaticImage
                                src="../images/france.jpg"
                                width={30}
                                quality={100}
                                alt="France"
                              />
                            </a>
                        </div>                    
                      </div>
                    </div>
                </li>
              </ul>  
            </div>            

            {/* Searchbar */}
            <div className="col-sm-8 col-md-7 mt-1 mb-4">
              <h4 className="text-white">Search</h4>
              <SearchBar />
            </div>

          </div>
        </div>
      </div>
      <div className="navbar navbar-dark bg-dark shadow-sm">

        {/* Site title & Header menu icon */}
        <div className="container">
          <a href={home} className="navbar-brand d-flex align-items-center">
            <strong>Gatsby &amp; Bootstrap</strong>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

      </div>

      {/* Logged in header */}
      <div className="user-loggedin-header">
        <div className="container">
          <div id="userLoggedInHeader"></div>
        </div> 
      </div>  
    </header>
  )

} 

export default Header
