/**
 * React.
 */
import React from "react"

/**
 * Global variable(s).
 * 
 * Notes:
 * Set custom global variables for dev/prod environments.
 * 
 * Example Var:
 * GATSBY_ENVIRONMENT="Development"
 * 
 * To add/view value(s) in current file(s),
 * Development: {root}/.env.development
 * Production:  {root}/.env.production
 * 
 * To reference the variable(s) in code use,
 * let foo    = process.env.{VARIABLE-NAME1}
 * const blah = process.env.{VARIABLE-NAME2}
 */
const _environment = process.env.GATSBY_ENVIRONMENT

const Footer = () => {

  /* Return footer */
  return (
      <div>
        <footer className="footer" >
          <div className="footer-copyright">
            &copy; {new Date().getFullYear()} {_environment} - Built using&nbsp;<a href="https://www.gatsbyjs.com">Gatsby</a> 
              &nbsp;&amp;&nbsp;<a href="https://getbootstrap.com/">Bootstrap</a> 
          </div>
        </footer>
      </div>
  )
} 

export default Footer
