/**
 * Async Storage.
 * https://react-native-async-storage.github.io/async-storage/docs/usage
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

const LoginStatus = () => {

  /* Check login status */
  (async () => {
    try {

      /* Get users logged in status */
      const jsonValue = await AsyncStorage.getItem('siteVals')
      const result = ( jsonValue != null ) ? JSON.parse( jsonValue ) : '' 

      /* If user is logged in */
      if ( result.userLoggedIn ) {

        /* Create logout button */
        let logoutButton = '<a href="javascript:void(0)" style="cursor:pointer;">Logout</a>'

        /* Display the logout button in header menu */
        document.getElementById( "/login/" ).innerHTML = logoutButton

        /* Add on-click event for logout button in header menu */
        document.getElementById( "/login/" ).addEventListener("click", setUserLoggedOut, false )

        /* Display content for logged in users */
        document.getElementById( "userLoggedInHeader" ).innerHTML = '<div class="user-loggedin-header-content show-logout-button">'
                                                                  + ' Welcome ' + result.userName + ', '
                                                                  + '</div>'

        /* Add logout button to user logged in header content */
        document.getElementsByClassName( "show-logout-button" )[0].innerHTML += logoutButton   
        
        /* Add on-click event for logout button in user logged in header content */
        document.getElementsByClassName( "show-logout-button" )[0].addEventListener("click", setUserLoggedOut, false )
      }

    } catch (e) {}
  })() 

  /* If user wants to logout */
  async function setUserLoggedOut() {

    /* Reset logged in status to false */
    const jsonValue = JSON.stringify( { userLoggedIn: false } )
    await AsyncStorage.setItem( "siteVals", jsonValue )

    /* Redirect to login */
    document.location = "/login";
    
  } 

  /* Return */
  return( 
    ''
  )

}
export default LoginStatus