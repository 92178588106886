/**
 * React.
 */
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

/**
 * Gatsby.
 */
import { useStaticQuery, graphql } from "gatsby"

/**
 * SEO.
 * 
 * Notes:
 * This page displays the SEO meta in a web page.
 */

function Seo({ description, lang, meta, title, yoastseo }) {

  /* Get site data */
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  /**
   * Set all SEO variables.
   *
   * Default.
   */
  let metaDescription    = description || site.siteMetadata.description
  let defaultTitle       = site.siteMetadata?.title
  let canonical          = ''
  let schemaRaw          = ''
  /**
   * OpenGraph.
   */
  let ogTitle            = ''
  let ogDescription      = ''
  let ogType             = ''
  let ogUrl              = ''
  let ogSiteName         = ''
  let ogImage            = ''
  let ogImageWidth       = ''
  let ogImageHeight      = ''
  let ogImageType        = ''
  /**
   * Twitter. 
   */
  let twitterCard        = ''
  let twitterCreator     = ''
  let twitterTitle       = ''
  let twitterDescription = ''
  let twitterImage       = ''

  /**
   * Update Yoast SEO variables 
   */
  if ( yoastseo ) {
    /**
     * Default. 
     */ 
    title           = ( yoastseo.title ) ? yoastseo.title : title 
    metaDescription = ( yoastseo.metaDesc ) ? yoastseo.metaDesc : metaDescription 
    canonical       = ( yoastseo.opengraphUrl ) ? yoastseo.opengraphUrl : '' 
    schemaRaw       = ( yoastseo.schema.raw ) ? yoastseo.schema.raw : '' 
    /** 
     * OpenGraph.
     */ 
    ogTitle       = ( yoastseo.opengraphTitle ) ? yoastseo.opengraphTitle : title 
    ogDescription = ( yoastseo.opengraphDescription ) ? yoastseo.opengraphDescription : metaDescription 
    ogType        = 'website'
    ogUrl         = ( yoastseo.opengraphUrl ) ? yoastseo.opengraphUrl : '' 
    ogSiteName    = ( yoastseo.opengraphSiteName ) ? yoastseo.opengraphSiteName : ''
    /* If we have an OpenGraph image */
    if ( yoastseo.opengraphImage ) {
      ogImage       = ( yoastseo.opengraphImage.mediaItemUrl ) ? yoastseo.opengraphImage.mediaItemUrl : ''
      ogImageWidth  = ( yoastseo.opengraphImage.mediaDetails.width ) ? yoastseo.opengraphImage.mediaDetails.width : ''
      ogImageHeight = ( yoastseo.opengraphImage.mediaDetails.height ) ? yoastseo.opengraphImage.mediaDetails.height : ''
      ogImageType   = ( yoastseo.opengraphImage.mimeType ) ? yoastseo.opengraphImage.mimeType : ''
    }
    /** 
     * Twitter.
     */ 
    twitterTitle       = ( yoastseo.twitterTitle ) ? yoastseo.twitterTitle : title 
    twitterDescription = ( yoastseo.twitterDescription ) ? yoastseo.twitterDescription : metaDescription 
    twitterCreator     = site.siteMetadata?.author || '' 
    twitterCard        = 'summary'
    /* If we have a Twitter image */
    if ( yoastseo.twitterImage ) {
      twitterImage       = ( yoastseo.twitterImage.mediaItemUrl ) ? yoastseo.twitterImage.mediaItemUrl : ''
    }
  } 

  /* Return SEO data */
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null} 
      meta={[
        {
          name: `description`,
          content: metaDescription, 
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:site_name`,
          content: ogSiteName,
        },
        {
          property: `og:image`, 
          content: ogImage,
        },
        {
          property: `og:image:width`,
          content: ogImageWidth,
        },
        {
          property: `og:image:height`,
          content: ogImageHeight,
        },
        {
          property: `og:image:type`,
          content: ogImageType,
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
        {
          name: `twitter:creator`,
          content: twitterCreator,
        },
        {
          name: `twitter:card`,
          content: twitterCard,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
      ].concat(meta)}
    >
    <link rel="canonical" href={canonical} />
    <script type="application/ld+json" class="yoast-schema-graph">{schemaRaw}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  yoastseo: PropTypes.object,
}

export default Seo
